.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.Navbar{
  background: #FE7E6D;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: white;
  justify-self: start;
  margin-left: 20px;
  font-family:'AbhayaLibre';
  cursor: pointer;
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links{
  color: white;
  text-decoration: none;
  padding: 0.5rem, 1rem;
  font-family:'AbhayaLibre';

}

.nav-links:hover {
  color: #FFEFEF;
  font: bold;
  font-size: xx-large;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: white;
}

.menu-icon {
  display: none;
}
