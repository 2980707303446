/* PAGE CONTAINER */
.projects-container {
  width: 100%;
  height: 100vh;
  background-image: url("/public/home_bg.png");
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* PAGE HEADER */
.projects-header-container {
  margin-top: 16px;
  width: 100%;
  height: 180px;
  background-image: url("/public/home_peachflower.png");
  background-position: 70px;
  background-size: 140%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-header {
  font-family: 'AbhayaLibre';
  font-size: 80px;
  margin-top: 50px;
  color: white;
}


/*PAGE CARD CONTAINER */
.card-section-container {
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
overflow: auto;

}

.language-header-ruby {
  width: 100%;
  height: 8%;
  background-color: #FE9C7B;
}

.language-header-ruby h1 {
  font-family: 'AbhayaLibre';
  text-indent: 10px;
  font-size: 45px;
  color: white;
}

/* CARDS */

.language-header-react {
  width: 100%;
  height: 8%;
  margin-top: 15px;
  background-color: #FE9C7B;
}

.language-header-react h1 {
  font-family: 'AbhayaLibre';
  text-indent: 10px;
  font-size: 45px;
  color: white;
}

/* CARDS */

.language-header-typescript {
  width: 100%;
  height: 8%;
  margin-top: 15px;
  background-color: #FE9C7B;
}

.language-header-typescript h1 {
  font-family: 'AbhayaLibre';
  text-indent: 10px;
  font-size: 45px;
  color: white;
}

/* CARDS */
