
/*FIRST SECTION*/
.get-to-know-me {
  margin-top: 18px;
  width: 100%;
  height: 258px;
  background-image: url("/public/aboutme2.png");
  background-size: 100% 450px;
  background-position: left;
}

/*SECOND SECTION - CONTAINERS*/
.my-journey-div {
  width: 100%;
  height: 65%;
  text-align: left;
  background-color: white;
}

.jcontent-container{
  width: 70%;
  height: 100%;
  margin: auto;
}

/*SECOND SECTION - CONTENT*/

/*Left Image*/
.img-box-1 {
  float: left;
  background: white;
  width: 25%;
  height: 100%;
  padding: 10px;
  margin-top: 30px;
}

.img-meandboo {
  height: 50%;
  background-image: url("/public/meandboo.png");
  background-size: cover;
  background-position: 0px;
  margin-top: 170px;
}

/*Central Text*/
.mystory-container{
  float: left;
  background: White;
  width: 50%;
  height: 100%;
  padding: 10px;
  margin-top: 20px;
}

.my-story-header {
  font-size: 50px;
  font-weight: bolder;
  font-family:'AbhayaLibre';
  text-align: center;
  color: #FE7E6D;
}

.the-beginning-header{
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight:bolder;
  text-align: center;
  color: navy;
}

.the-beginning-text-1 {
  padding: 8px 60px 8px 60px;
  font-size: 11px;
  font-family: 'Montserrat';
  text-align: center;
}

.the-beginning-text-2, .the-beginning-text-3, .the-beginning-text-4, .the-beginning-text-5 {
  padding: 0px 60px 8px 60px;
  font-size: 11px;
  font-family: 'Montserrat';
  text-align: center;
}

.my-vision-header {
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bolder;
  text-align: center;
  color: navy;
}

.my-vision-text-1 {
  padding: 8px 60px 8px 60px;
  font-size: 11px;
  font-family: 'Montserrat';
  text-align: center;
}

.my-vision-text-2 {
  padding: 0px 60px 8px 60px;
  font-size: 11px;
  font-family: 'Montserrat';
  text-align: center;
}

/*Right Image*/
.img-box-2 {
  float: right;
  background:white;
  width: 25%;
  height: 100%;
  padding: 10px;
}

.img-lewagon {
  height: 50%;
  background-image: url("/public/lewagon.png");
  background-size: cover;
  background-position: center;
  margin-top: 35px;
}



/*THIRD SECTION*/
.neurodiversity {
  margin-top: 6px;
  width: 100%;
  height: 258px;
  background-image: url("/public/nuero.png");
  background-size: contain;
  opacity: 0.7;
}

.nd-header {
  margin-top: 120px;

}
.nd-header-text {
  font-size: 55px;
  font-weight: bolder;
  font-family: 'AbhayaLibre';
  text-align: center;
  color: navy;
}

/*FIFTH SECTION - CONTAINERS*/

.neurodiversity-div {
  width: 100%;
  height: 65%;
  text-align: left;
  background-color: white;
}

.ndcontent-container {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.nd-box, .creative-box, .empath-box, .mentor-box, .problem-solver-box, .transfer-box{
  width: 33.33%;
  height: 50%;
}

.box-content-container{
  width: 96%;
  height: 95%;
  background-color: white;
  margin-top: 5px;
  margin-left: 6px;
  display: flex;
  flex-direction: row;
  padding: 10px;
}


/*FIFTH SECTION - BOX CONTENT*/

.icon-column{
  flex-grow: 2;
  width: 45%;
  height: 100%;
}

.nd-icon{
  margin-top: 34px;
  color:#FE7E6D;
  height: 100%;
  width: 100%;
  text-align: center;
}

.side-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  margin-left: 4px;
}

.box-header {
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: bolder;
  text-align: left;
  color: navy;
  height: 12%;
}

.box-text {
  flex-grow: 1;
  text-align:start;
  font-size: 11px;
  font-family: 'Montserrat';
}

.p {
  margin-top: 5px;
}
