.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

.card-container{
  display: grid;
  place-items: center;
  width: 80vw;
  max-width: 18rem;
  height: 20rem;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.card-container > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.card-background {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}


.card-contents{
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  height: 70%;
  padding: 13%;
  background: linear-gradient(180deg,
  hsla(0, 0%, 0%, 0) 0%,
  hsl(0deg 0% 0% / 13%) 10%,
  hsl(0deg 0% 0% / 27%) 100%);
}

.card-contents-container {
  --flow-space: 1.25rem;
}

.card-title,
.card-description {
  margin: 0;
}

.card-title {
  font-size: 27px;
  font-family: 'AbhayaLibre';
  color: white;
  line-height: 0.2;
  position: relative;
  width: fit-content;
}

.card-language {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 1.5;
  margin-top: 20px;
  color: white;
}

.card-description {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 1.5;
  margin-top: 10px;
  color: white;
}

.card-title::after {
  content: "";
  position: absolute;
  height: 0.15rem;
  width: calc(100% + 1.25rem);
  top: 19px;
  left: -1.25rem;
  background-color:#FE7E6D;
}


.card-button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content;
  border-radius: 0.45em;
  border: none;
  background-color: #FE7E6D;
}


.card-button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}


.p-link {
  text-decoration: none;
  font-family: 'Montserrat';
  font-size: 10px;
  color: white;
}



@media (any-hover: hover) and (any-pointer: fine) {

  .card-contents {
    transform: translateY(75%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
  }

  .card-title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .card-background {
    transition: transform 500ms ease-in;
  }

  .card-contents-container> :not(.card-title),
  .card-button {
    opacity: 0;
    transition: transform 500ms ease-out, opacity 500ms ease-out;
  }

  .card-container:hover,
  .card-container:focus-within {
    transform: scale(1.05);
    transition: transform 500ms ease-in;
  }

  .card-container:hover .card-contents,
  .card-container:focus-within .card-contents {
    transform: translateY(0);
    transition: transform 500ms ease-in;
  }

  .card-container:focus-within .card-contents {
    transition-duration: 0ms;
  }

  .card-container:hover .card-background,
  .card-container:focus-within .card-background {
    transform: scale(1.3);
  }

  .card-container:hover .card-contents-container> :not(.card-title),
  .card-container:hover .card-button,
  .card-container:focus-within .card-contents-container> :not(.card-title),
  .card-container:focus-within .card-button {
    opacity: 1;
    transition: opacity 500ms ease-in;
    transition-delay: 1000ms;
  }

  .card-container:hover .card-title::after,
  .card-container:focus-within .card-title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transition-delay: 500ms;
  }
}
