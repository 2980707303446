/* Custom Fonts */

/*@font-face {
  font-family: "PlayfairDisplay";
  src: local("PlayfairDisplay"),
    url('./fonts/PlayfairDisplay[wght].ttf') format("truetype");
}*/

/* Default CSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}



.app-container{
  background-color: #FFEFEF;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
