.homepage-container {
  width: 100%;
  height: 100%;
  background-image: url("/public/home_bg.png");
  overflow: hidden;
}

/* MAIN CONTENT */

.profile-container {
  height: 80%;
  display: flex;
  margin-top: 88px;
  margin-left: 20px;
}

/* TEXT CONTENT */

.profile-text-container {
  height: 100%;
  width: 40%;
}

.header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-family: 'Montserrat';
  text-align: left;
  height: 30%;
  width: 100%;
  margin-left: 10px;
}

.first-name-text {
  color: #FE7E6D;
  font-size: 70px;
  font-family: 'AbhayaLibre';
}

.header2-text {
  color: #0F6292;
  font-size: 25px;

}

.break-container {
  width: 100%;
  height: 5%;
}

.break {
  color: navy;
  font-weight: bolder;
}

.blurb-container {
  color: #7C99AC;
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  margin-top: 8px;
  font-family: 'Montserrat';
}

.blurb-text-1 {
  font-size: 17px;
}

.blurb-text-2 {
  font-size: 17px;
  margin-top: 5px;
}

.blurb-text-3 {
  font-size: 17px;
  margin-top: 15px;
}



/*CV BUTTON LINK*/


/* SOCIAL LINK CONTENT */

.link-container {
  color: #FFEFEF;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
  margin-left: 5px;
}

.icon {
  color: #FE7E6D;
  height: 40px;
  width: 45px;
  margin-left: 1px;
  --fa-animation-duration: 2s
}

/* IMAGE CONTENT */

.profile-image-container {
  width: 70%;
  height: 100%;
  background-color: #FFEFEF;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
}

/* Column 1 */

.profile-img-col-1 {
  margin-top: 5px;
  margin-bottom: 8px;
  width: 40%;
  height: 100%;
  align-items: center;
}

.col1-photo1 {
  height: 80%;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-image: url("/public/home_me.png");
  background-size: cover;
  background-position: 35%;
}

.col1-photo2 {
  height: 18.5%;
  width: 100%;
  margin-bottom: 5px;
  background-image: url("/public/home_peachflower.png");
  background-size: 590px;
  background-position: center;
}


/* Column 2 */
.profile-img-col-2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 20%;
  height: 100%;
}

.col2-photo1 {
  height: 30%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/terrazzo.png");
  background-size: 180px;

}

.col2-photo2 {
  height: 27%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/mac.png");
  background-size: 180px;
  background-position: center;
}

.col2-photo3 {
  height: 40%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/home_medown.png");
  background-size: 80px;
  background-position: center;
}

/* Column 3 */

.profile-img-col-3 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 15%;
  height: 100%;
}

.col3-photo1 {
  height: 35%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/home_peachflower.png");
  background-size: 300px;
  background-position: center;
}


.col3-photo2 {
  height: 40%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/home_meup.png");
  background-size: cover;
  background-position: right;
}

.col3-photo3 {
  height: 22%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/mac.png");
  background-size: 145px;
  background-position: center;
}

/* Column 4 */

.profile-img-col-4 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 5px;
  margin-right: 5px;
  width: 20%;
  height: 100%;
}

.col4-photo1 {
  height: 35%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/home_mesketch.png");
  background-size: cover;
  background-position: 16%;
}

.col4-photo2 {
  height: 25%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/home_medown.png");
  background-size: 40px;
  background-position: center;
}

.col4-photo3 {
  height: 20%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/mac.png");
  background-size: 135px;
  background-position: center;

}

.col4-photo4 {
  height: 15%;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url("/public/terrazzo.png");
  background-size: cover;
}
