.cv-container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}

.cv {
  width: 53%;
  height: 100%;
  margin-top: 2px;
  margin-bottom: 10px;
  background-image: url("/public/Sophie_Mattacks_CV.png");
  background-size: cover;
}
